import React, { useState, useEffect, useRef } from 'react';
import './GlyphLockDial.css';
import glyph0 from './art/lock_glyph0.svg';
import glyph1 from './art/lock_glyph1.svg';
import glyph2 from './art/lock_glyph2.svg';
import glyph3 from './art/lock_glyph3.svg';
import glyph4 from './art/lock_glyph4.svg';
import glyph5 from './art/lock_glyph5.svg';
import glyph6 from './art/lock_glyph6.svg';
import glyph7 from './art/lock_glyph7.svg';
import glyph8 from './art/lock_glyph8.svg';
import glyph9 from './art/lock_glyph9.svg';
import glyph10 from './art/lock_glyph10.svg';
import glyph11 from './art/lock_glyph11.svg';
import glyph12 from './art/lock_glyph12.svg';

const glyphSVG = [
  glyph0,
  glyph1,
  glyph2,
  glyph3,
  glyph4,
  glyph5,
  glyph6,
  glyph7,
  glyph8,
  glyph9,
  glyph10,
  glyph11,
  glyph12,
];
const NUM_GLYPHS = glyphSVG.length;

function GlyphImage({index}) {
  return <img src={glyphSVG[index]} alt={'glyph'+index} draggable="false" />;
}

function useMouseDragDeltas(onDelta, onRelease) {
  const dragState = useRef(null);

  const clearDragState = () => {
    if (dragState.current) {
      const {onMouseMove, onMouseUp} = dragState.current;
      document.removeEventListener('mousemove', onMouseMove, false);
      document.removeEventListener('mouseup', onMouseUp, false);
    }
    dragState.current = null;
  };

  const onMouseDown = (e) => {
    clearDragState();

    const onMouseMove = (e) => {
      const ds = dragState.current;
      const dx = e.clientX - ds.lastPos.x;
      const dy = e.clientY - ds.lastPos.y;
      onDelta({x: dx, y: dy});
      ds.lastPos = {x: e.clientX, y: e.clientY};
    };

    const onMouseUp = (e) => {
      clearDragState();
      onRelease();
    }

    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('mouseup', onMouseUp, false);

    dragState.current = {
      lastPos: {x: e.clientX, y: e.clientY},
      onMouseMove,
      onMouseUp,
    };
  };

  useEffect(() => {
    // just clean up
    return () => {
      clearDragState();
    };
  }, [])

  return {onMouseDown};
}

function useBlockDocumentTouchMove() {
  const maybeBlockTouchMove = useRef();
  const blocking = useRef(false);

  if (!maybeBlockTouchMove.current) {
    const maybeBlock = (e) => {
      if (blocking.current) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', maybeBlock, {passive: false});

    maybeBlockTouchMove.current = maybeBlock;
  }
  useEffect(() => {
    // just clean up
    return () => {
      document.removeEventListener('touchmove', maybeBlockTouchMove.current, {passive: false});
    }
  }, []);

  return (block) => {
    blocking.current = block;
  };
}

function useTouchDragDeltas(onDelta, onRelease) {
  const setBlocking = useBlockDocumentTouchMove();
  const lastPos = useRef(null);

  const onTouchStart = (e) => {
    lastPos.current = {x: e.touches[0].clientX, y: e.touches[0].clientY};
    setBlocking(true);
  };

  const onTouchMove = (e) => {
    const tx = e.touches[0].clientX;
    const ty = e.touches[0].clientY;

    if (lastPos.current) {
      const dx = tx - lastPos.current.x;
      const dy = ty - lastPos.current.y;
      onDelta({x: dx, y: dy});
      lastPos.current = {x: tx, y: ty};
    }
  };

  const onTouchEnd = (e) => {
    lastPos.current = null;
    onRelease();
    setBlocking(false);
  };

  return {onTouchStart, onTouchMove, onTouchEnd};
}

export default function GlyphLockDial({initialIndex, onIndexChange, unlockedStyle}) {
  const ADJACENT_SHOWN_FRAC = 0.25; // If glyph is centered, what portion of adjacent glyphs are shown.
  const SNAP_THRESH = 0.2;

  const [fracIndex, setFracIndex] = useState(initialIndex % NUM_GLYPHS); // should be >= 0 and less than NUM_GLYPHS

  const DIAL_HEIGHT_FRAC = 1+2*ADJACENT_SHOWN_FRAC;

  const shownIndexes = [];
  for (let i = -1; i < NUM_GLYPHS+1; i++) {
    shownIndexes.push(i);
  }

  const snapIfClose = (v) => ((Math.abs(v - Math.round(v)) < SNAP_THRESH) ? (Math.round(v) % NUM_GLYPHS) : v)

  const snappedFracIndex = snapIfClose(fracIndex);

  const onDelta = (delta) => {
    setFracIndex(oldFracIndex => {
      let newFracIndex = oldFracIndex + -0.02*delta.y;
      while (newFracIndex < 0) {
        newFracIndex += NUM_GLYPHS;
      }
      newFracIndex %= NUM_GLYPHS;
      return newFracIndex;
    });
  };
  const onRelease = () => {
    setFracIndex(oldFracIndex => {
      const snapped = snapIfClose(oldFracIndex);
      onIndexChange(snapped);
      return snapped;
    });
  };

  const mouseDragHandlers = useMouseDragDeltas(onDelta, onRelease);
  const touchDragHandlers = useTouchDragDeltas(onDelta, onRelease);

  const fracToPct = v => ((100*v).toFixed(4) + '%');

  return (
    <div className="GlyphLockDial">
      <div className="GlyphLockDial-inner" style={{paddingTop: fracToPct(DIAL_HEIGHT_FRAC)}} {...mouseDragHandlers} {...touchDragHandlers}>
        <div>
          {shownIndexes.map(idx => (
            <div key={idx} className="GlyphLockDial-positioner" style={{top: fracToPct((idx + ADJACENT_SHOWN_FRAC - snappedFracIndex)/DIAL_HEIGHT_FRAC)}}><GlyphImage index={(idx+NUM_GLYPHS) % NUM_GLYPHS} /></div>
          ))}
        </div>
        <div className="GlyphLockDial-above-frame" style={{bottom: fracToPct(1 - ADJACENT_SHOWN_FRAC/DIAL_HEIGHT_FRAC)}} />
        <div className="GlyphLockDial-below-frame" style={{top: fracToPct(1 - ADJACENT_SHOWN_FRAC/DIAL_HEIGHT_FRAC)}} />
        <div className={'GlyphLockDial-frame' + (unlockedStyle ? ' GlyphLockDial-frame-unlocked' : '')} style={{top: fracToPct(ADJACENT_SHOWN_FRAC/DIAL_HEIGHT_FRAC), bottom: fracToPct(ADJACENT_SHOWN_FRAC/DIAL_HEIGHT_FRAC)}} />
      </div>
    </div>
  )
}
