import React, { useState } from 'react';
import GlyphLockDial from './GlyphLockDial';
import './GlyphLock.css';

export default function GlyphLock({initialDigits, onDigitsChange, unlockedStyle}) {
  const [digits, setDigits] = useState(initialDigits);

  const handleIndexChange = (placeIndex, glyphIndex) => {
    setDigits(oldDigits => {
      const newDigits = [...oldDigits];
      newDigits[placeIndex] = glyphIndex;

      onDigitsChange(newDigits);

      return newDigits;
    });
  };

  return (
    <div className="GlyphLock">
      {digits.map((digit, idx) => (
        <div key={idx} className="GlyphLock-dial"><GlyphLockDial initialIndex={digit} onIndexChange={(glyphIndex) => { handleIndexChange(idx, glyphIndex) }} unlockedStyle={unlockedStyle} /></div>
      ))}
    </div>
  );
}
